import React from 'react'
import { graphql } from 'gatsby'

import { Layout, SEO } from '@globals'

const PageTemplate = ({ data }) => {
  // define the pages content
  // kind of like a serializer
  const content = (data.sanityPage._rawContent || [])
    .filter((c) => !c.disabled)
    .map((c, i) => {
      let el = null
      switch (c._type) {
        /*         case 'content':
          const { Content } = require('@layouts')
          el = <Content key={c._key} {...c} />
          break */
        case 'modelList':
          const { ModelList } = require('@layouts')
          el = <ModelList key={c._key} {...c} />
          break
        case 'hero':
          const { Hero } = require('@layouts')
          el = <Hero key={c._key} {...c} />
          break
        case 'features':
          const { Features } = require('@layouts')
          el = <Features key={c._key} {...c} />
          break
        default:
          const { ComingSoon } = require('@utils/components')
          el = <ComingSoon key={c._key} {...c} />
          break
      }
      return el
    })
  return (
    <>
      <SEO
        title={data.sanityPage?.openGraph?.title || data.sanityPage?.title}
        description={data.sanityPage?.openGraph?.description || null}
        image={data.sanityPage?.openGraph?.image?.asset?.fixed?.src || null}
        disallowRobots={data.sanityPage.disallowRobots || null}
      />
      <Layout>{content}</Layout>
    </>
  )
}

export default PageTemplate

export const PAGE_QUERY = graphql`
  query ($_id: String!) {
    sanityPage(_id: { eq: $_id }) {
      title
      _rawContent(resolveReferences: { maxDepth: 10 })
      ...OpenGraphPage
      disallowRobots
    }
  }
`
